import React, { useEffect, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import './add-product.css'
import AdminButton from '../../utils/adminButton/AdminButton'
import InfoModal from '../../utils/infoModal/InfoModal'
import { type AudienceType, type ProductType } from '../../../utils/types'
import productService from '../../../services/productService'
import audienceService from '../../../services/audienceService'
import { formatProductData } from '../../../utils/utils'
import AdminDropdown from '../../utils/adminDropdown/AdminDropdown'

interface AddProductsProps {
  closeModal: () => void
  onAddProduct: (product: ProductType) => void
}

const AddProducts: React.FC<AddProductsProps> = ({
  closeModal,
  onAddProduct
}) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ProductType>({
    defaultValues: {
      Audience: null,
      brand: '',
      code: '',
      label: '',
      minToOrder: null,
      maxToOrder: null,
      minToWinPoints: null,
      maxToWinPoints: null,
      pointValue: null
    }
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalText, setModalText] = useState('')
  const [shouldCloseModal, setShouldCloseModal] = useState(false)
  const [audiences, setAudiences] = useState<AudienceType[]>([])

  const maxToWinPoints = watch('maxToWinPoints')
  const minToWinPoints = watch('minToWinPoints')

  const pointValueRequired = Boolean(maxToWinPoints || minToWinPoints)

  const allOptions = [
    { value: -1, label: '<vide>' },
    ...audiences.map((audience) => ({
      value: audience.id,
      label: audience.name
    }))
  ]

  const onSubmit: SubmitHandler<ProductType> = (data: ProductType) => {
    const formatt = (str: any): number | null => {
      if (/^0+$/.test(str)) {
        return 0
      }

      return Number(str) || null
    }

    const numericMinToOrder = formatt(data.minToOrder)
    const numericMaxToOrder = formatt(data.maxToOrder)
    const numericMinToWinPoints = formatt(data.minToWinPoints)
    const numericMaxToWinPoints = formatt(data.maxToWinPoints)
    const numericPointValue = formatt(data.pointValue)
    if (
      numericPointValue === 0 &&
      (numericMinToWinPoints !== 1 || numericMaxToWinPoints !== 1)
    ) {
      setModalText(
        'Les valeurs renseignées pour les min/max points sont incohérentes'
      )
      setIsModalOpen(true)
      setShouldCloseModal(false)
      // eslint-disable-next-line no-useless-return
      return
    }

    if (
      (numericMinToOrder &&
        numericMaxToOrder &&
        numericMinToOrder > numericMaxToOrder) ||
      (numericMinToWinPoints &&
        numericMaxToWinPoints &&
        numericMinToWinPoints > numericMaxToWinPoints)
    ) {
      setModalText(
        'Les valeurs renseignées pour les min/max produits sont incohérentes.'
      )
      setIsModalOpen(true)
      setShouldCloseModal(false)
      // eslint-disable-next-line no-useless-return
      return
    }
    if (
      numericMinToWinPoints &&
      numericMinToOrder &&
      numericMinToWinPoints < numericMinToOrder &&
      numericPointValue !== 0
    ) {
      setModalText(
        'Les valeurs renseignées pour les min/max points sont incohérentes'
      )
      setIsModalOpen(true)
      setShouldCloseModal(false)
      // eslint-disable-next-line no-useless-return
      return
    }
    if (
      numericMaxToWinPoints &&
      numericMaxToOrder &&
      numericMaxToWinPoints > numericMaxToOrder
    ) {
      setModalText(
        'Les valeurs renseignées pour les min/max points sont incohérentes'
      )
      setIsModalOpen(true)
      setShouldCloseModal(false)
      // eslint-disable-next-line no-useless-return
      return
    }
    if (
      numericPointValue &&
      (numericMaxToWinPoints === null || numericMinToWinPoints === null)
    ) {
      setModalText(
        'Les valeurs renseignées pour les min/max points sont incohérentes'
      )
      setIsModalOpen(true)
      setShouldCloseModal(false)
      // eslint-disable-next-line no-useless-return
      return
    } else {
      data.minToOrder = numericMinToOrder
      data.maxToOrder = numericMaxToOrder
      data.minToWinPoints = numericMinToWinPoints
      data.maxToWinPoints = numericMaxToWinPoints
      data.pointValue = numericPointValue
      data.Audience = data.Audience && data.Audience.id === -1 ? null : data.Audience
      productService
        .createProduct(formatProductData(data) as ProductType)
        .then((res) => {
          setModalText('Le produit a été crée avec succès')
          setIsModalOpen(true)
          onAddProduct(res)
          setShouldCloseModal(true)
        })
        .catch(() => {
          setModalText('Une erreur est survenue lors de la création du produit')
          setIsModalOpen(true)
        })
    }
  }

  useEffect(() => {
    const fetchAudiences = async (): Promise<void> => {
      try {
        const response = await audienceService.getAllAudiences()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setAudiences(response)
      } catch (error) {
        console.error('Failed to fetch audiences:', error)
      }
    }
    void fetchAudiences()
  }, [])

  return (
    <>
      <form className='audience-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='inputs-container'>
          <div className='input-form-container'>
            <p className='input-label'>Audience</p>
            <AdminDropdown
              options={allOptions}
              placeholder='Select Audience'
              value={watch('Audience.id')}
              onChange={(value): void => {
                setValue('Audience.id', value as number)
              }}
            />
            {errors.Audience && (
              <p className='validation-error'>{errors.Audience.message}</p>
            )}
          </div>
          <div className='input-form-container'>
            <p className='input-label'>Marque*</p>
            <input
              className='input-text'
              {...register('brand', {
                required: 'Ce champ est obligatoire'
              })}
            />
            {errors.brand && (
              <p className='validation-error'>{errors.brand.message}</p>
            )}
          </div>
          <div className='input-form-container'>
            <p className='input-label'>Libellé Produit*</p>
            <input
              className='input-text'
              {...register('label', {
                required: 'Ce champ est obligatoire'
              })}
            />
            {errors.label && (
              <p className='validation-error'>{errors.label.message}</p>
            )}
          </div>
          <div className='input-form-container'>
            <p className='input-label'>Code Produit*</p>
            <input
              className='input-text'
              {...register('code', {
                required: 'Ce champ est obligatoire'
              })}
            />
            {errors.code && (
              <p className='validation-error'>{errors.code.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Minimum Produit</p>
            <input
              className='input-text'
              type='number'
              {...register('minToOrder', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractères saisis sont incorrects'
                },
                min: {
                  value: 0,
                  message: 'Les caractères saisis sont incorrects'
                }
              })}
            />
            {errors.minToOrder && (
              <p className='validation-error'>{errors.minToOrder.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Maximum Produit</p>
            <input
              type='number'
              className='input-text'
              {...register('maxToOrder', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractères saisis sont incorrects'
                },
                max: { value: 999, message: 'La valeur maximale est de 999' },
                min: {
                  value: 0,
                  message: 'Les caractères saisis sont incorrects'
                }
              })}
            />
            {errors.maxToOrder && (
              <p className='validation-error'>{errors.maxToOrder.message}</p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Point Quantité Minimum</p>
            <input
              type='number'
              className='input-text'
              {...register('minToWinPoints', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractères saisis sont incorrects'
                },
                min: { value: 0, message: 'La valeur minimale est de 1' }
              })}
            />
            {errors.minToWinPoints && (
              <p className='validation-error'>
                {errors.minToWinPoints.message}
              </p>
            )}
          </div>

          <div className='input-form-container'>
            <p className='input-label'>Point Quantité Maximum</p>
            <input
              type='number'
              className='input-text'
              {...register('maxToWinPoints', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractères saisis sont incorrects'
                },
                max: { value: 999, message: 'La valeur maximale est de 999' }
              })}
            />
            {errors.maxToWinPoints && (
              <p className='validation-error'>
                {errors.maxToWinPoints.message}
              </p>
            )}
          </div>

          <div className='input-form-container full-width'>
            <p className='input-label'>Nombre de points</p>
            <input
              type='number'
              className='input-text'
              {...register('pointValue', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Les caractères saisis sont incorrects'
                },
                required:
                  pointValueRequired &&
                  'Ce champ est requis lorsque Point Quantité Minimum ou Point Quantité Maximum est renseigné',
                max: { value: 999, message: 'La valeur maximale est de 999' },
                min: {
                  value: 0,
                  message: 'Les caractères saisis sont incorrects'
                }
              })}
            />
            {errors.pointValue && (
              <p className='validation-error'>{errors.pointValue.message}</p>
            )}
          </div>
        </div>
        <AdminButton type='submit'>Valider</AdminButton>
      </form>
      <InfoModal
        isOpen={isModalOpen}
        title='Ajouter un nouveau produit'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
          if (shouldCloseModal) {
            closeModal()
          }
        }}
      />
    </>
  )
}

export default AddProducts
