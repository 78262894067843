import React, { useEffect, useState } from 'react'
import './list-product.css'
import { type ProductType } from '../../../utils/types'
import AdminTable from '../../utils/adminTable/AdminTable'
import InfoModal from '../../utils/infoModal/InfoModal'
import Modal from '../../utils/modal/Modal'
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import productService from '../../../services/productService'
import EditProduct from '../editProduct/EditProduct'
import imageService from '../../../services/imageService'
import AddImage from '../addImage/AddImage'
import ToggleButton from '../../utils/toggleButton/ToggleButton'
import ConfirmationModal from '../../utils/confirmationModal/ConfirmationModal'

interface ListProductProps {
  productsList: ProductType[]
}

const ListProduct: React.FC<ListProductProps> = ({ productsList }) => {
  const [products, setProducts] = useState<ProductType[]>(productsList)
  const [selectedProduct, setSelectedProduct] = useState<Partial<ProductType>>({
    brand: '',
    code: '',
    label: '',
    minToOrder: null,
    maxToOrder: null,
    minToWinPoints: null,
    maxToWinPoints: null,
    pointValue: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [currentProduct, setCurrentProduct] = useState<ProductType>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [
    isBulkDeleteConfirmationModalOpen,
    setIsBulkDeleteConfirmationModalOpen
  ] = useState(false)
  const [isRankConfirmationModalOpen, setIsRankConfirmationModalOpen] =
    useState(false)
  const [rankToUpdate, setRankToUpdate] = useState<null | {
    product: ProductType
    newRank: number
    audienceId: number
  }>(null)
  const [selectedProducts, setSelectedProducts] = useState<number[]>([])
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false)

  const columns = [
    { header: 'ID', accessor: 'id' },
    {
      header: 'RANG',
      accessor: 'rank',
      render: (row: ProductType) => (
        <>
          {' '}
          <input
            type='number'
            className='rank-input'
            value={row.rank}
            onChange={(e) => {
              const newRank = parseInt(e.target.value, 10)
              setProducts((prevProducts) =>
                prevProducts.map((p) =>
                  p.id === row.id ? { ...p, rank: newRank } : p
                )
              )
            }}
            onBlur={() => {
              row.rank &&
                row.Audience?.id &&
                handleRankBlur(row, row.rank, row.Audience?.id)
            }}
          />
        </>
      )
    },
    {
      header: 'AUDIENCE',
      accessor: 'audience',
      render: (row: ProductType) => <>{row.Audience?.name || '--'}</>
    },
    {
      header: 'AUDIENCE',
      accessor: 'audience',
      render: (row: ProductType) => <>{row.Audience?.name || '--'}</>
    },
    { header: 'MARQUE', accessor: 'brand' },
    { header: 'CODE PRODUIT', accessor: 'code' },
    { header: 'LIBELLE PRODUIT', accessor: 'label' },
    { header: 'MINIMUM PRODUIT', accessor: 'minToOrder' },
    { header: 'MAXIMUM PRODUIT', accessor: 'maxToOrder' },
    { header: 'POINT QUANTITÉ MINIMUM', accessor: 'minToWinPoints' },
    { header: 'POINT QUANTITÉ MAXIMUM', accessor: 'maxToWinPoints' },
    { header: 'NOMBRE DE POINTS', accessor: 'pointValue' },
    {
      header: 'IMAGES',
      accessor: 'images',
      render: (row: ProductType) => {
        return (
          <>
            <img
              src={imageService.getImageUrl(row.imageUrl || '')}
              alt='Product'
              style={{ width: '50px', height: '50px', cursor: 'pointer' }}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              onClick={async () => {
                await handleOpenImageModal(row)
              }}
            />
          </>
        )
      }
    },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: ProductType) => (
        <div className='actions-cell'>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updateProduct(row)
            }}
            style={{ color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              setSelectedProduct(row)
              setIsDeleteConfirmationModalOpen(true)
            }}
          >
            Delete
          </DeleteIcon>
          <ToggleButton
            enabled={row.enabled}
            onClick={() => {
              handleToggle(row)
            }}
          />
        </div>
      )
    },
    {
      header: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              if (selectedProducts.length === 0) {
                console.log('No products selected.')
              } else {
                setIsBulkDeleteConfirmationModalOpen(true) // Open bulk delete confirmation modal
              }
            }}
            style={{ cursor: 'pointer' }}
          />
          <input
            type='checkbox'
            onChange={(e) => {
              if (e.target.checked) {
                // Select all products if checked
                setSelectedProducts(products.map((product) => product.id))
              } else {
                // Deselect all products if unchecked
                setSelectedProducts([])
              }
            }}
            checked={
              selectedProducts.length === products.length && products.length > 0
            }
          />
        </div>
      ),
      accessor: 'select',
      render: (row: ProductType) => (
        <input
          type='checkbox'
          checked={selectedProducts.includes(row.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedProducts([...selectedProducts, row.id])
            } else {
              setSelectedProducts(
                selectedProducts.filter((id) => id !== row.id)
              )
            }
          }}
        />
      )
    }
  ]

  const handleRankBlur = (
    product: ProductType,
    newRank: number,
    audienceId: number
  ): void => {
    const filteredProducts = products.filter(
      (p) => p.Audience?.id === product.Audience?.id
    )
    if (newRank > filteredProducts.length) {
      newRank = filteredProducts.length
    }
    if (newRank < 1) {
      newRank = 1
    }
    console.log('new Rank: ', newRank)
    setRankToUpdate({ product, newRank, audienceId })
    setIsRankConfirmationModalOpen(true) // Open the modal when input loses focus
  }

  const handleRankChange = async (
    product: ProductType,
    newRank: number,
    audienceId: number
  ): Promise<void> => {
    try {
      // Call the API to update the rank
      await productService
        .updateProductRank(product.id, newRank, audienceId)
        .then(async () => {
          const response = await productService.getAllProducts()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setProducts(response)
        })
    } catch (error) {
      console.error('Failed to update rank:', error)
    }
  }

  const handleToggle = (product: ProductType): void => {
    setSelectedProduct(product)
    setIsConfirmationModalOpen(true)
  }

  const confirmToggle = async (): Promise<void> => {
    if (selectedProduct?.id) {
      const newEnabledStatus = !selectedProduct.enabled
      await productService.disableProduct(selectedProduct.id, newEnabledStatus)
      const updatedProducts = products.map((p) =>
        p.id === selectedProduct.id ? { ...p, enabled: newEnabledStatus } : p
      )
      setProducts(updatedProducts)
    }
    setIsConfirmationModalOpen(false)
  }

  const cancelToggle = (): void => {
    setIsConfirmationModalOpen(false)
  }

  const deleteProductAction = async (id: number): Promise<void> => {
    try {
      await productService.deleteProduct(id).then(async () => {
        setmodalText('Le produit a été supprimé avec succès')
        setIsModalOpen(true)
        const response = await productService.getAllProducts()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setProducts(response)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateProduct = (product: ProductType): void => {
    setSelectedProduct(product)
    setIsEditModalOpen(true)
  }

  const handleEditProduct = (updatedProduct: ProductType): void => {
    setProducts(
      products.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      )
    )
  }

  useEffect(() => {
    setProducts(productsList)
  }, [productsList])

  const fetchImages = async (): Promise<void> => {
    const urls = await imageService.listImages()
    const notFoundIndex = urls.findIndex((url) =>
      url.includes('image_placeholder')
    )
    if (notFoundIndex > 0) {
      const [notFoundUrl] = urls.splice(notFoundIndex, 1)
      urls.unshift(notFoundUrl)
    }
    setImageUrls(urls)
  }

  const handleOpenImageModal = async (product: ProductType): Promise<void> => {
    try {
      setCurrentProduct(product)
      setIsImageModalOpen(true)
      await fetchImages()
    } catch (error) {
      console.error('Failed to delete image:', error)
    }
  }

  return (
    <div className='products-container-list'>
      <AdminTable columns={columns} data={products} />

      <InfoModal
        isOpen={isModalOpen}
        title='Supprimer le produit'
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Modifier un produit'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditProduct
          productProp={selectedProduct}
          closeModal={() => {
            setIsEditModalOpen(false)
          }}
          onEditProduct={handleEditProduct}
        />
      </Modal>
      <Modal
        isOpen={isImageModalOpen}
        title='Associer une nouvelle image au produit'
        onClose={() => {
          setIsImageModalOpen(false)
        }}
      >
        <AddImage
          closeModal={() => {
            setIsImageModalOpen(false)
          }}
          imageUrls={imageUrls}
          currentProduct={currentProduct}
          setProducts={setProducts}
        />
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title="Indication d'un produit en rupture"
        message={
          selectedProduct.enabled
            ? 'Attention, le produit ne sera plus visible par le point de vente.'
            : 'Le produit sera de nouveau visible pour le point de vente.'
        }
        subMessage={'Confirmez-vous ?'}
        onClose={cancelToggle}
        onConfirme={confirmToggle}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title='Supprimer un produit'
        message='Voulez-vous supprimer ce produit ?'
        onClose={() => {
          setIsDeleteConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (selectedProduct.id) {
            await deleteProductAction(selectedProduct.id)
            setIsDeleteConfirmationModalOpen(false)
          }
        }}
      ></ConfirmationModal>
      <ConfirmationModal
        isOpen={isRankConfirmationModalOpen}
        title='Confirmer la modification du rang'
        message={`Voulez-vous vraiment modifier le rang du produit ${rankToUpdate?.product.id} à ${rankToUpdate?.newRank}?`}
        onClose={() => {
          setIsRankConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (rankToUpdate) {
            await handleRankChange(
              rankToUpdate.product,
              rankToUpdate.newRank,
              rankToUpdate.audienceId
            )
            setIsRankConfirmationModalOpen(false)
          }
        }}
      />
      <ConfirmationModal
        isOpen={isBulkDeleteConfirmationModalOpen}
        title='Confirmer la suppression en masse'
        message={`Êtes-vous sûr de vouloir supprimer les produits suivants: ${selectedProducts.join(', ')}?`}
        onClose={() => { setIsBulkDeleteConfirmationModalOpen(false) } }
        onConfirme={async () => {
          try {
            await productService.bulkDeleteProducts(selectedProducts)
            setSelectedProducts([]) // Clear selected products
            const updatedProducts = await productService.getAllProducts()
            setProducts(updatedProducts)
            setIsBulkDeleteConfirmationModalOpen(false) // Close modal after deletion
          } catch (error) {
            console.log('Failed to delete products')
          }
        }}
      />
    </div>
  )
}

export default ListProduct
